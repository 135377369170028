<template>
  <div>
    <div v-if="isLoading">
      <OtherLoading />
    </div>
    <div v-else>
      <b-row class="no-gutters">
        <b-col>
          <h1 class="mr-sm-4 header-tablepage">RECEIPT DETAIL</h1>
        </b-col>
      </b-row>
      <div class="title-tabs mt-3">General Information</div>
      <b-container class="no-gutters bg-white">
        <div class="py-3">
          <b-row>
            <b-col sm="6">
              <InputText
                textFloat="Name"
                placeholder="Name"
                v-model="form.name"
                isRequired
                type="text"
                :isValidate="$v.form.name.$error"
                :v="$v.form.name"
              />
            </b-col>
            <b-col sm="6">
              <div class="position-icon" v-if="this.id !== '0'">
                <button
                  type="button"
                  class="btn-icon mr-1"
                  v-clipboard:copy="form.path_url_key"
                  v-clipboard:success="onCopySuccess"
                  v-clipboard:error="onCopyError"
                >
                  <font-awesome-icon icon="copy" />
                </button>
              </div>
              <InputText
                textFloat="Url Key"
                placeholder="Url Key"
                type="text"
                name="urlkey"
                isRequired
                :isValidate="$v.form.url_key.$error"
                :v="$v.form.url_key"
                v-model.trim="form.url_key"
                textValidate="Please Input URL Key"
                @onKeypress="checkURLKey($event)"
              />
            </b-col>
          </b-row>
          <b-row>
            <b-col xs sm="6">
              <b-form-group class="mb-2">
                <template v-slot:label>
                  <p class="mb-2">Type <span class="text-danger">*</span></p>
                </template>
                <b-form-radio-group
                  id="detect-type"
                  v-model="form.receipt_scanner_type_id"
                  :options="detectTypeOptions"
                  name="detect-type-options"
                  @change="handleTypeChange"
                ></b-form-radio-group>
              </b-form-group>
            </b-col>
            <b-col xs sm="12" md lg xl="3">
              <InputText
                textFloat="Max Upload Per Day"
                placeholder="Max Upload Per Day"
                type="number"
                name="user_limit"
                isRequired
                v-model="form.user_limit"
                :v="$v.form.user_limit"
              />
            </b-col>
            <b-col xs sm="12" md lg xl="3">
              <InputText
                textFloat="Limit Error Per Day"
                placeholder="Limit Error Per Day"
                type="number"
                name="error_limit"
                isRequired
                v-model="form.error_limit"
                :v="$v.form.error_limit"
              />
            </b-col>
          </b-row>
          <b-row>
            <b-col sm="6">
              <UploadFile
                textFloat="Banner"
                placeholder="Please Choose File"
                format="video"
                :fileName="form.banner"
                v-model="form.banner"
                name="thumbnail"
                text="*Please upload only .png, .jpg image and .mp4 less than 150 MB"
                isRequired
                :accept="acceptType"
                v-on:onFileChange="onImageChange"
                :v="$v.form.banner"
              />
              <PreviewBox
                v-on:delete="deleteImage"
                :showPreviewType="showPreviewType"
                :showPreview="showPreview"
                :isLoadingImage="isLoadingImage"
                :ratioType="4"
              />
            </b-col>
            <b-col cols="6"
              ><div class="main-label">Point</div>
              <b-form-checkbox
                :id="'receipt-no-point'"
                class="mb-3"
                v-model="form.no_point"
                :name="'receipt-no-point'"
                :value="1"
                :unchecked-value="0"
              >
                No Point
              </b-form-checkbox>
            </b-col>
          </b-row>
          <b-row class="mt-3">
            <b-col cols="12" class="mb-3">
              <label for="" class="main-label">Scan Receipt Valid Date</label>
              <b-form-radio-group v-model="form.receipt_valid_type">
                <b-form-radio :value="1">Custom</b-form-radio>
                <b-form-radio :value="2">Past X Days</b-form-radio>
              </b-form-radio-group>
            </b-col>
            <template v-if="form.receipt_valid_type == 1">
              <b-col lg="6" md="6" sm="12" xs="12" class="mb-md-0 mb-3">
                <label class="label-text">
                  Receipt Date From <span class="text-danger">*</span></label
                >

                <div
                  :class="[
                    'input-container',
                    { error: $v.form.receipt_valid_from.$error },
                  ]"
                >
                  <datetime
                    type="datetime"
                    v-model="form.receipt_valid_from"
                    placeholder="Please Select Date"
                    format="dd/MM/yyyy (HH:mm)"
                    value-zone="Asia/Bangkok"
                    @input="handleMinDate"
                    ref="receipt_valid_from"
                  >
                  </datetime>
                  <div
                    :class="
                      'icon-primary text-right ' + id == 0
                        ? ''
                        : 'cursor-default'
                    "
                    @click="$refs.receipt_valid_from.isOpen = true"
                  >
                    <font-awesome-icon
                      icon="calendar-alt"
                      :class="
                        id == 0
                          ? 'pointer color-primary'
                          : 'cursor-default color-primary'
                      "
                      color="#B41BB4"
                    />
                  </div>
                </div>
                <div v-if="$v.form.receipt_valid_from.$error">
                  <span
                    class="text-error"
                    v-if="$v.form.receipt_valid_from.required == false"
                    >Please select date time.</span
                  >
                </div>
              </b-col>
              <b-col lg="6" md="6" sm="12" xs="12">
                <label class="label-text">
                  Receipt Date To <span class="text-danger">*</span></label
                >
                <div
                  :class="[
                    'input-container',
                    { error: $v.form.receipt_valid_to.$error },
                  ]"
                >
                  <datetime
                    type="datetime"
                    v-model="form.receipt_valid_to"
                    placeholder="Please Select Date"
                    format="dd/MM/yyyy (HH:mm)"
                    value-zone="Asia/Bangkok"
                    :min-datetime="form.receipt_valid_from"
                    ref="receipt_valid_to"
                  >
                  </datetime>
                  <div
                    :class="
                      'icon-primary text-right ' + id == 0
                        ? ''
                        : 'cursor-default'
                    "
                    @click="$refs.receipt_valid_to.isOpen = true"
                  >
                    <font-awesome-icon
                      icon="calendar-alt"
                      :class="
                        id == 0
                          ? 'pointer color-primary'
                          : 'cursor-default color-primary'
                      "
                      color="#B41BB4"
                    />
                  </div>
                </div>
                <div v-if="$v.form.receipt_valid_to.$error">
                  <span
                    class="text-error"
                    v-if="$v.form.receipt_valid_to.required == false"
                    >Please select date time.</span
                  >
                </div>
              </b-col>
            </template>
            <template v-else>
              <b-col lg="6" md="6" sm="12" xs="12" class="mb-md-0 mb-3">
                <InputText
                  textFloat="Days"
                  placeholder="Valid Days"
                  v-model="form.receipt_past_day"
                  isRequired
                  type="number"
                  :oninput="'javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, 4);'"
                  :isValidate="$v.form.receipt_past_day.$error"
                  :v="$v.form.receipt_past_day"
                />
              </b-col>
            </template>
          </b-row>
          <b-row class="mt-3">
            <b-col cols="12" class="mb-1">
              <label for="" class="main-label">Valid Date</label>
            </b-col>
            <b-col lg="6" md="6" sm="12" xs="12" class="mb-md-0 mb-3">
              <label class="label-text">
                Valid From <span class="text-danger">*</span></label
              >

              <div
                :class="[
                  'input-container',
                  { error: $v.form.valid_from.$error },
                ]"
              >
                <datetime
                  type="datetime"
                  v-model="form.valid_from"
                  placeholder="Please Select Date"
                  format="dd/MM/yyyy (HH:mm)"
                  value-zone="Asia/Bangkok"
                  @input="handleMinDate"
                  ref="valid_from"
                >
                </datetime>
                <div
                  :class="
                    'icon-primary text-right ' + id == 0 ? '' : 'cursor-default'
                  "
                  @click="$refs.valid_from.isOpen = true"
                >
                  <font-awesome-icon
                    icon="calendar-alt"
                    :class="
                      id == 0
                        ? 'pointer color-primary'
                        : 'cursor-default color-primary'
                    "
                    color="#B41BB4"
                  />
                </div>
              </div>
              <div v-if="$v.form.valid_from.$error">
                <span
                  class="text-error"
                  v-if="$v.form.valid_from.required == false"
                  >Please select date time.</span
                >
              </div>
            </b-col>
            <b-col lg="6" md="6" sm="12" xs="12">
              <label class="label-text">
                Valid To <span class="text-danger">*</span></label
              >
              <div
                :class="['input-container', { error: $v.form.valid_to.$error }]"
              >
                <datetime
                  type="datetime"
                  v-model="form.valid_to"
                  placeholder="Please Select Date"
                  format="dd/MM/yyyy (HH:mm)"
                  value-zone="Asia/Bangkok"
                  :min-datetime="form.valid_from"
                  ref="valid_to"
                >
                </datetime>
                <div
                  :class="
                    'icon-primary text-right ' + id == 0 ? '' : 'cursor-default'
                  "
                  @click="$refs.valid_to.isOpen = true"
                >
                  <font-awesome-icon
                    icon="calendar-alt"
                    :class="
                      id == 0
                        ? 'pointer color-primary'
                        : 'cursor-default color-primary'
                    "
                    color="#B41BB4"
                  />
                </div>
              </div>
              <div v-if="$v.form.valid_to.$error">
                <span
                  class="text-error"
                  v-if="$v.form.valid_to.required == false"
                  >Please select date time.</span
                >
              </div>
            </b-col>
          </b-row>
          <hr />
          <div class="panelborder p-3 mt-3">
            <b-row>
              <b-col sm="12">
                <label class="ft-16 main-label">Products</label>
                <b-form-group class="d-flex mt-2">
                  <b-form-radio-group
                    v-model="form.is_all_product"
                    name="product-radios"
                    class="text-black"
                    :options="isAllProductOption"
                  ></b-form-radio-group>
                </b-form-group>
              </b-col>
              <b-col sm="12" v-if="form.is_all_product == 0">
                <div class="content-between">
                  <div>
                    <b-button
                      class="btn-select-branch"
                      @click="openProductModal"
                    >
                      Select Product<font-awesome-icon
                        icon="chevron-right"
                        class="ml-2"
                      />
                    </b-button>
                    <span class="ml-2"
                      >Selected Product
                      {{ form.product_list.length | numeral("0,0") }}
                      Item</span
                    ><span
                      class="ml-2 text-error"
                      v-if="$v.form.product_list.$error"
                      >Please select product at least 1.</span
                    >
                  </div>
                </div>
                <div class="mt-3">
                  <b-table
                    responsive
                    striped
                    hover
                    :fields="fieldsProduct"
                    :items="productSelect"
                    :busy="isBusy"
                    show-empty
                    empty-text="Not Found."
                  >
                    <template v-slot:cell(imageUrl)="data">
                      <div>
                        <div
                          v-if="data.item.imageUrl"
                          class="box-image"
                          v-bind:style="{
                            'background-image':
                              'url(' + data.item.imageUrl + ')',
                          }"
                        ></div>
                        <div v-else>-</div>
                      </div>
                    </template>
                    <template v-slot:cell(name)="data">
                      <div class="text-left">
                        <div v-if="data.item.name">
                          <p class="m-0">
                            {{ data.item.name }}
                          </p>
                          <p class="m-0 text-gray">
                            {{ data.item.barcode }}
                          </p>
                          <p class="m-0 text-gray">
                            {{ data.item.price | numeral("0,0.00") }}
                          </p>
                        </div>
                        <p v-else class="m-0">-</p>
                      </div>
                    </template>
                    <template v-slot:cell(delete)="data">
                      <span class="pointer" @click="deleteProduct(data.item)"
                        ><b-icon
                          icon="trash-fill"
                          class="text-bg-primary"
                        ></b-icon
                      ></span>
                    </template>
                    <template v-slot:table-busy>
                      <div class="text-center text-black my-2">
                        <b-spinner class="align-middle"></b-spinner>
                        <strong class="ml-2">Loading...</strong>
                      </div>
                    </template>
                  </b-table>
                </div>
                <div>
                  <b-row>
                    <b-col
                      cols="12"
                      md="6"
                      class="form-inline justify-content-start"
                    >
                      <p class="mb-0 p-gray">
                        Showing
                        {{ rows > 0 ? showing + (filter.page - 1) : 0 }} to
                        {{ showingTo > rows ? rows : showingTo }} of
                        {{ rows }} entries
                      </p>
                    </b-col>
                    <b-col
                      cols="12"
                      md="6"
                      class="form-inline justify-content-end"
                    >
                      <b-pagination
                        v-model="filter.page"
                        :total-rows="rows"
                        :per-page="filter.length"
                        first-text="First"
                        prev-text="Prev"
                        next-text="Next"
                        last-text="Last"
                        class="m-md-0"
                        @change="pagination"
                      ></b-pagination>
                      <b-form-select
                        class="ml-1"
                        v-model="filter.length"
                        @change="handleChangeTake"
                        :options="pageOptions"
                      ></b-form-select>
                    </b-col>
                  </b-row>
                </div>
                <hr />
              </b-col>
              <b-col sm="12">
                <label class="col-form-label">Branches</label>
                <ModalSelectBranch
                  ref="modalSelectBranch"
                  v-if="branchList && branchList.length > 0"
                  :list="branchList"
                  @selectBranch="selectBranch"
                  text="Select Branch"
                  :field="form"
                  :BranchSelectList="form.branch_list"
                  :id="id == 0 ? 'noSelect' : id.toString()"
                />
                <div class="text-error" v-if="$v.form.branch_list.$error">
                  Please select at least 1 branch.
                </div>
              </b-col>
            </b-row>
          </div>
        </div>
      </b-container>
      <div class="no-gutters bg-white mt-3 py-2 px-3">
        <b-form-checkbox
          switch
          v-model="form.status"
          class="radio-active"
          size="lg"
          :value="1"
          :unchecked-value="0"
        >
          <span class="ml-2 main-label">{{
            form.status ? "Active" : "Inactive"
          }}</span>
        </b-form-checkbox>
      </div>
      <FooterAction @submit="checkForm()" routePath="/setting/receipt" />
    </div>
    <ProductModal v-on:submitProduct="submitProduct" ref="ProductListModal" />
    <ModalAlertError ref="modalAlertError" :text="modalMessage" />
    <b-modal
      v-model="importModal"
      no-close-on-backdrop
      centered
      title="Import Product"
      hide-footer
    >
      <div>
        <b-row class="no-gutters">
          <b-col md="12">
            <UploadFileV2
              textFloat="File"
              placeholder="Please Choose File"
              format="excel"
              name="file"
              text="*Please upload only file .xlsx less than 10 MB"
              isRequired
              v-on:onFileChange="onFileChange"
              v-on:delete="deleteFile"
              :fileName="formImport.filename"
              v-model="formImport.excel"
              :v="$v.formImport.excel"
              id="uploadfile"
            />
          </b-col>
        </b-row>

        <b-row>
          <b-col>
            <b-button size="md" class="btn-cancel" @click="importModal = false"
              >Cancel</b-button
            >
          </b-col>
          <b-col class="text-right">
            <b-button
              id="upload"
              class="main-color btn-save color-btn"
              size="md"
              :disabled="isDisable"
              @click="importProduct"
              >Upload</b-button
            >
          </b-col>
        </b-row>
      </div>
    </b-modal>
    <ModalCopyUrl ref="ModalCopyUrl" :urlWeb="linkWeb" :urlLine="linkLine" />
  </div>
</template>

<script>
import {
  required,
  requiredIf,
  minValue,
  minLength,
} from "vuelidate/lib/validators";
import OtherLoading from "@/components/loading/OtherLoading";
import ModalSelectBranch from "@/components/ModalSelectBranch";
import ProductModal from "./components/ProductModal.vue";
import ModalAlertError from "@/components/modal/ModalAlertError";
import UploadFile from "@/components/inputs/UploadFile";
import PreviewBox from "@/components/PreviewBox.vue";
import ModalCopyUrl from "@/components/form/modal/ModalCopyUrl";

export default {
  name: "ReceiptDetail",
  components: {
    OtherLoading,

    ModalSelectBranch,
    ProductModal,
    ModalAlertError,
    UploadFile,
    PreviewBox,
    ModalCopyUrl,
  },

  data() {
    return {
      id: parseInt(this.$route.params.id),
      branchList: [],
      productSelect: [],
      originalProductSelect: [],
      originalBranchSelect: [],
      rows: 0,
      showing: 1,
      showingTo: 10,
      filter: {
        page: 1,
        length: 10,
        start: 0,
        DeleteProductList: [],
        onlyInRedeem: 1,
        search: "",
        selectProductList: [],
      },
      form: {
        id: 0,
        url_key: "",
        name: "",
        receipt_scanner_type_id: 1,
        status: 1,
        banner: "",
        branch_list: [],
        product_list: [],
        error_limit: 0,
        user_limit: 0,
        valid_from: "",
        valid_to: "",
        options: [],
        is_all_product: 1,
        receipt_valid_type: 1,
        receipt_valid_from: "",
        receipt_valid_to: "",
        receipt_past_day: 0,
      },
      fieldsProduct: [
        {
          key: "imageUrl",
          label: "Image",
        },
        {
          key: "name",
          label: "Product Name (SKU)",
        },
        {
          key: "delete",
          label: "",
        },
      ],
      pageOptions: [
        { value: 10, text: "10 / page" },
        { value: 30, text: "30 / page" },
        { value: 50, text: "50 / page" },
        { value: 100, text: "100 / page" },
      ],
      isAllProductOption: [
        { text: "All Products", value: 1 },
        { text: "Selected Item", value: 0 },
      ],
      detectTypeOptions: [
        { value: 1, text: "Keyword" },
        { value: 2, text: "Bill" },
      ],
      modalMessage: "",
      checkFormStatus: false,
      isBusy: false,
      isLoading: true,
      importModal: false,
      isDisable: false,
      formImport: {
        excel: "",
        file_name: "",
      },
      acceptType: "image/jpeg, image/png, video/mp4",
      showPreview: null,
      showPreviewType: 0,
      isLoadingImage: false,
      linkWeb: "",
      linkLine: "",
    };
  },
  validations() {
    return {
      form: {
        name: { required },
        url_key: { required },
        product_list: {
          required: requiredIf(function () {
            return this.form.is_all_product == 0;
          }),
          minLength: minLength(1),
        },
        receipt_valid_from: {
          required: requiredIf(function () {
            return this.form.receipt_valid_type == 1;
          }),
        },
        receipt_valid_to: {
          required: requiredIf(function () {
            return this.form.receipt_valid_type == 1;
          }),
        },
        receipt_past_day: {
          required: requiredIf(function () {
            return this.form.receipt_valid_type == 2;
          }),
        },
        branch_list: { required, minLength: minLength(1) },
        user_limit: { required, minValue: minValue(0) },
        error_limit: { required, minValue: minValue(0) },
        receipt_scanner_type_id: { required, minValue: minValue(1) },
        valid_from: { required },
        valid_to: { required },
        banner: { required },
      },
      formImport: {
        excel: { required },
        file_name: { required },
      },
    };
  },
  async created() {
    if (this.id == 0) {
      await this.getBranchList(this.form, 0);
    } else {
      await this.getDetail();
      await this.getProductSelect();
      await this.getBranchList(this.form, this.form.branch_group_id);
    }
    this.isLoading = false;
  },
  methods: {
    onCopySuccess: function (e) {
      this.successAlert(`${e.text}`, "You just copied");
    },
    onCopyError: function (e) {
      this.$swal("Failed to copy", `${e.text}`, "error");
    },
    openModalLink() {
      this.$refs.ModalCopyUrl.show();
    },
    async getDetail() {
      this.isLoading = true;
      await this.$store.dispatch("getReceiptScanDetail", this.id);
      let data = this.$store.state.receiptscan.stateReceiptScanDetail;
      if (data.result == 1) {
        this.form = data.detail;
        this.originalProductSelect = this.form.product_list;
        this.originalBranchSelect = this.form.branch_list;
        this.form.is_all_product =
          this.form.product_list && this.form.product_list.length > 0 ? 0 : 1;
        this.showPreview = this.form.banner;
        this.form.branch_list = this.form.branch_list || [];
        this.form.product_list = this.form.product_list || [];
      } else {
        this.isLoading = false;
        this.errorAlert(data.message).then(() =>
          this.$router.push(`/setting/receipt/monitor/${this.urlKey}`)
        );
      }
    },
    async getBranchList(field, id) {
      await this.$store.dispatch("getBranch", id);
      this.branchList = this.$store.state.coupon.branchList.detail;
    },
    selectBranch(value) {
      this.form.branch_list = value;
    },
    async getProductSelect() {
      this.isBusy = true;
      this.filter.start = (this.filter.page - 1) * this.filter.length;
      this.filter.selectProductList = this.form.product_list.map((e) => {
        return { id: e, point: 0 };
      });
      await this.$store.dispatch("getListProductSelect", this.filter);
      const data = this.$store.state.redeem.productSelectList;
      if (data.result == 1) {
        this.productSelect = await data.detail.data;
        await this.productSelect.forEach((e, index) => {
          e.index = index;
        });
        this.rows = data.detail.totalSelectProduct;
      } else {
        this.productSelect = [];
        this.rows = 0;
      }
      this.isBusy = false;
    },
    handleTypeChange(val) {
      this.form.receipt_scanner_type_id = val;
    },
    async openProductModal() {
      await this.$refs.ProductListModal.show(this.form.product_list);
    },
    submitProduct(select) {
      select = select.map((e) => {
        return e.id;
      });

      this.filter.DeleteProductList = this.filter.DeleteProductList.filter(
        (el) => !select.includes(el)
      );

      this.form.product_list = select;
      this.filter.page = 1;
      this.getProductSelect();
    },
    async deleteProduct(data) {
      await this.filter.DeleteProductList.push(
        this.productSelect[data.index].id
      );
      await this.productSelect.splice(data.index, 1);
      this.form.product_list = this.form.product_list.filter(
        (item) => item != data.id
      );
      this.productSelect.forEach((e, index) => {
        e.index = index;
      });
      if (this.productSelect.length == 0) {
        if (this.filter.page > 1) {
          this.filter.page -= 1;
        }
      }
      this.getProductSelect();
    },
    pagination(value) {
      this.filter.page = value;
      if (value === 1) {
        this.showing = this.filter.page;
        this.showingTo = this.filter.length;
      } else {
        this.showing = value * this.filter.length - this.filter.length;
        this.showingTo = this.showing + this.filter.length;
      }
      this.getProductSelect();
    },
    handleChangeTake(length) {
      this.filter.page = 1;
      this.filter.length = length;
      this.showingTo = this.filter.length;
      this.getProductSelect();
    },
    checkForm() {
      this.checkFormStatus = true;
      this.$v.form.$touch();
      if (this.$v.form.$error) {
        return;
      }
      this.submit();
    },
    async submit() {
      let payload = this.form;
      let data = null;
      this.$bus.$emit("showLoading");
      if (payload.branch_list.length == 0) payload.branch_list = null;
      if (payload.product_list.length == 0) payload.product_list = null;

      if (this.id == 0) {
        await this.$store.dispatch("createReceiptScan", payload);
        data = this.$store.state.receiptscan.stateCreateReceiptScan;
      } else {
        await this.$store.dispatch("updateReceiptScan", payload);
        data = this.$store.state.receiptscan.stateUpdateReceiptScan;
      }
      this.$bus.$emit("hideLoading");
      if (data.result == 1) {
        this.successAlert().then(() => this.$router.push("/setting/receipt"));
      } else {
        this.errorAlert(data.message);
      }
    },
    onFileChange(file) {
      this.formImport.filename = file.name;
      this.isDisable = false;
      const str =
        "data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,";
      let reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        this.formImport.excel = reader.result.substring(
          str.length,
          reader.result.length
        );
      };
    },
    deleteFile(value) {
      this.formImport.excel = null;
      this.formImport.filename = null;
      this.isDisable = true;
    },
    async importProduct() {
      let payload = {
        excel_file: this.formImport.excel,
      };
      if (this.importModal) {
        this.$bus.$emit("showLoading");
        const result = await this.$Axios.post(
          `${process.env.VUE_APP_API}/import/getproductbyimport`,
          payload
        );
        if (result.data.result) {
          let product = [
            ...this.form.product_list.map((el) => {
              return { id: el };
            }),
            ...result.data.detail.productId.map((el) => {
              return { id: el };
            }),
          ];
          this.submitProduct(product);
          this.importModal = false;
        } else {
          this.errorAlert(result.data.message);
        }
        // this.$bus.$emit("showLoading");
        this.$bus.$emit("hideLoading");
      } else {
        this.importModal = true;
      }
    },
    handleMinDate() {
      let diff = this.$moment(this.form.valid_to).diff(
        this.$moment(this.form.valid_from),
        "min"
      );

      if (diff < 0) this.form.valid_to = "";
    },
    onImageChange(val) {
      this.isLoadingImage = true;
      this.isDisable = true;
      var reader = new FileReader();
      reader.readAsDataURL(val);
      reader.onload = async () => {
        if (val.type === "video/mp4") {
          var url = await this.saveImagetoDb(reader.result, true);
          this.showPreviewType = 1;
          this.isLoadingImage = false;
          this.isDisable = false;
          this.showPreview = url;
          this.form.banner = url;
        } else {
          let img = new Image();
          img.onload = () => {
            this.ratioMatch = true;
            this.setUpdataImage(reader.result);
          };
          img.src = await reader.result;
        }
      };
    },
    deleteImage() {
      this.form.banner = "";
      this.showPreview = null;
    },
    async setUpdataImage(base64) {
      if (this.ratioMatch === true) {
        var url = await this.saveImagetoDb(base64, false);
        this.showPreviewType = 0;
        this.isLoadingImage = false;
        this.isDisable = false;
        this.showPreview = url;
        this.form.banner = url;
      }
    },
    saveImagetoDb: async function (img, isvideo) {
      var form = {
        Base64String: img,
        is_video: isvideo,
      };
      await this.$store.dispatch("ActionUploadImage", form);
      var data = await this.$store.state.moduleConnectAPI.stateUploadImage;
      if (data.result == 1) {
        return data.detail;
      } else {
        alert("Unsuccess!");
      }
    },
    checkURLKey(e) {
      let regex = new RegExp("^[a-zA-Z0-9_]+$");
      let str = String.fromCharCode(!e.charCode ? e.which : e.charCode);

      if (regex.test(str)) {
        return true;
      }

      e.preventDefault();
      return false;
    },
  },
};
</script>

<style lang="scss" scoped>
.ft-16 {
  font-size: 16px;
  color: var(--font-main-color);
}

.bg-gray {
  background-color: #f7f7f7;
}

.text-gray {
  color: #939393;
}

.input-container.error {
  border-color: red !important;
}

// ::v-deep .branch-modal > div {
//   margin: 0px 0px 4px 0px !important;
// }
.condition-title {
  color: var(--primary-color);
  font-size: 16px;
}

.btn-add-condition {
  color: white;
  background-color: var(--primary-color);
  display: flex;
  justify-content: center;
  align-items: center;
  border: none !important;
}

.panelborder {
  border: 1px solid #d8dbe0;
}

.btn-icon {
  background-color: transparent;
  border: none;
  font-size: 16px;
}

.position-icon {
  position: absolute;
  z-index: 999;
  left: 90px;
}
</style>
